<template>
  <div class="box-style grid grid-cols-1 gap-5">
    <div class="row-start-1 border-b border-cool-gray pb-5">
      <h3 class="thirdhead-text pb-3">Student Filters</h3>
      <div class="pb-3">
        <input type="text" class="rounded w-full" placeholder="Filter by student name" v-model="filters.student_name" />
      </div>
      <div class="pb-3">
        <Multiselect :classes="classes" :options="practice_areas_options" :searchable="true" mode="tags"
          placeholder="Any practice area" v-model="filters.student_practice_area" />
      </div>
      <div class="pb-3">
        <Multiselect :classes="classes" :options="this.lookup_transportation_options" :searchable="true" mode="tags"
          placeholder="Any transportation" v-model="filters.student_transportation" />
      </div>
      <div class="pb-3">
        <Multiselect :classes="classes" :options="acad_levels_options" :searchable="true" mode="tags"
          placeholder="Any grade level" v-model="filters.student_acad_level" />
      </div>
      <div>
        <div class="pb-1">
          <input type="checkbox" id="paired" v-model="filters.student_paired" />
          <label class="px-1" for="paired">Paired Students</label>
        </div>
        <div class="pb-1">
          <input type="checkbox" id="unpaired" v-model="filters.student_unpaired" />
          <label class="px-1" for="unpaired">Unpaired Students</label>
        </div>
      </div>
    </div>
    <div class="row-start-2 border-b border-cool-gray pb-5">
      <h3 class="thirdhead-text pb-3">Mentor Filters</h3>
      <div class="pb-3">
        <input type="text" class="rounded w-full" placeholder="Filter by mentor name" v-model="filters.mentor_name" />
      </div>
      <div class="pb-3">
        <Multiselect :classes="classes" :options="practice_areas_options" :searchable="true" mode="tags"
          placeholder="Any practice area" v-model="filters.mentor_practice_area" />
      </div>
      <div class="pb-3">
        <Multiselect :classes="classes" :options="mentor_employers_options" :create-option="true" :searchable="true"
          mode="tags" placeholder="Any employer" v-model="filters.mentor_employer_name"
          @search-change="$emit('SearchEmployer', $event)" />
      </div>
      <div class="pb-3">
        <Multiselect :classes="classes" :options="mentor_job_titles_options" :create-option="true" :searchable="true"
          mode="tags" placeholder="Any job title" v-model="filters.mentor_job_titles" />
      </div>
      <div class="pb-3">
        <Multiselect :classes="classes" :options="lookup_mentor_status_options" :searchable="true" mode="tags"
          placeholder="Any status" v-model="filters.mentor_status" />
      </div>
      <div class="pb-3">
        <Multiselect :classes="classes" :options="mentor_cities_options" :create-option="true" :searchable="true"
          mode="tags" placeholder="Any city" v-model="filters.mentor_city" />
      </div>
      <div>
        <div class="pb-1">
          <input type="checkbox" id="paired-mentors" v-model="filters.mentor_paired" />
          <label class="px-1" for="paired-mentors">Paired Mentors</label>
        </div>
        <div class="pb-1">
          <input type="checkbox" id="unpaired-mentors" v-model="filters.mentor_unpaired" />
          <label class="px-1" for="unpaired-mentors">Unpaired Mentors</label>
        </div>
      </div>
    </div>
    <div class="row-start-3">
      <div class="flex justify-between">
        <button class="inline-block py-1 px-3 bg-purple hover:bg-dark-purple rounded-full text-white"
          aria-label="apply-pairing-filters" @click="applyFilters">
          Apply Filters
        </button>
        <button
          class="inline-block border border-purple py-1 px-3 bg-white text-purple hover:bg-dark-purple rounded-full hover:text-white"
          aria-label="reset-pairing-filters" @click="resetFilters">
          Reset Filters
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "@vueform/multiselect";

export default {
  name: "Filters",
  emits: ["ApplyFilters", "SearchEmployer"],
  components: { Multiselect },
  props: {
    mentor_employers_options: {
      type: Array,
      default: () => [],
    },
    mentor_job_titles_options: {
      type: Array,
      default: () => [],
    },
    mentor_cities_options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      classes: {
        container:
          "relative w-full flex items-center justify-start box-border cursor-pointer border border-gray-300 rounded bg-white text-base leading-snug outline-none",
        containerDisabled: "cursor-default bg-gray-100",
        containerOpen: "rounded-b-none",
        containerOpenTop: "rounded-t-none",
        containerActive: "ring ring-green-500 ring-opacity-30",
        singleLabel:
          "flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border",
        singleLabelText:
          "overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full",
        multipleLabel:
          "flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5",
        search:
          "w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5",
        tags: "flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2",
        tag: "bg-purple text-white text-sm py-0.5 pl-2 rounded mr-1 mb-1 flex items-center",
        tagDisabled: "pr-2 opacity-50",
        tagRemove:
          "flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group",
        tagRemoveIcon:
          "bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60",
        tagsSearchWrapper:
          "inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full",
        tagsSearch:
          "absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-base font-sans box-border w-full",
        tagsSearchCopy: "invisible whitespace-pre-wrap inline-block h-px",
        placeholder:
          "flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-placeholder-gray text-2xl",
        caret:
          "bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none",
        caretOpen: "rotate-180 pointer-events-auto",
        clear:
          "pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80",
        clearIcon:
          "bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block",
        spinner:
          "bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0",
        dropdown:
          "max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b",
        dropdownTop:
          "-translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t",
        dropdownHidden: "hidden",
        options: "flex flex-col p-0 m-0 list-none",
        optionsTop: "flex-col-reverse",
        group: "p-0 m-0",
        groupLabel:
          "flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal",
        groupLabelPointable: "cursor-pointer",
        groupLabelPointed: "bg-gray-300 text-gray-700",
        groupLabelSelected: "bg-purple text-white",
        groupLabelDisabled: "bg-gray-100 text-gray-300 cursor-not-allowed",
        groupLabelSelectedPointed: "bg-purple text-white opacity-90",
        groupLabelSelectedDisabled:
          "text-green-100 bg-purple bg-opacity-50 cursor-not-allowed",
        groupOptions: "p-0 m-0",
        option:
          "flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3",
        optionPointed: "text-white bg-purple",
        optionSelected: "text-white bg-purple",
        optionDisabled: "text-white cursor-not-allowed bg-slate-gray",
        optionSelectedPointed: "text-white bg-purple opacity-90",
        optionSelectedDisabled:
          "text-green-100 bg-purple bg-opacity-50 cursor-not-allowed",
        noOptions: "py-2 px-3 text-gray-600 bg-white text-left",
        noResults: "py-2 px-3 text-gray-600 bg-white text-left",
        fakeInput:
          "bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent",
        spacer: "h-9 py-px box-content",
      },
      filters: {
        mentor_name: "",
        mentor_practice_area: [],
        mentor_employer_name: [],
        mentor_job_titles: [],
        mentor_status: ["Active"],
        mentor_city: [],
        mentor_paired: false,
        mentor_unpaired: false,

        student_name: "",
        student_practice_area: [],
        student_transportation: [],
        student_acad_level: [],
        student_paired: false,
        student_unpaired: false,
        student_status: ["Active"],
      },
    };
  },
  methods: {
    applyFilters: function () {
      let appliedFilters = {
        filters: Object.keys(this.filters).reduce((acc, key) => {
          console.log(`FILTER NAME ::: ${key}`)
          console.log(`FILTER VALUE ::: ${this.filters[key]}`)
          console.log(`FILTER TYPE ::: ${typeof this.filters[key]}`)
          if (typeof this.filters[key] === "boolean") {
            acc[key] = this.filters[key];
       } else if (this.filters[key] && this.filters[key].length) {
            acc[key] = this.filters[key];
          }
          return acc;
        }, {}),
      };
      appliedFilters["reset_pages"] = true;
      console.log("APPLIED FILTERS::", appliedFilters)
      this.$emit("ApplyFilters", { ...appliedFilters });
    },
    resetFilters: function () {
      this.filters = {
        mentor_name: "",
        mentor_practice_area: null,
        mentor_employer_name: null,
        mentor_job_titles: null,
        mentor_status: ["Active"],
        mentor_city: null,
        mentor_paired: false,
        mentor_unpaired: false,

        student_name: "",
        student_practice_area: null,
        student_transportation: null,
        student_acad_level: null,
        student_paired: false,
        student_unpaired: false,
        student_status: ["Active"],
      };
      this.$emit("ApplyFilters", { filters: this.filters });
    },
  },
  computed: {
    acad_levels_options: function () {
      let arr = [];
      for (let i in this.lookup_acad_levels) {
        arr.push(this.lookup_acad_levels[i].level);
      }
      return arr;
    },
    practice_areas_options: function () {
      let arr = [];
      for (let i in this.lookup_practice_areas) {
        arr.push({
          label: this.lookup_practice_areas[i].description,
          value: this.lookup_practice_areas[i].practice_area_id,
        });
      }
      return arr;
    },
    states_options: function () {
      let arr = [];
      for (let i in this.lookup_states) {
        arr.push(this.lookup_states[i]);
      }
      return arr;
    },
    ...mapGetters([
      "lookup_acad_levels",
      "lookup_practice_areas",
      "lookup_transportation_options",
      "lookup_states",
      "lookup_mentor_status_options",
    ]),
  },
};
</script>

<style scoped></style>
