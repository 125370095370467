<template>
  <div>
    Addresses:
    <div v-if="addresses.length" class="p-1">
      <div v-for="(addr, idx) in addresses" :key="idx" class="pb-1">
        <div v-if="showAddress(addr.address_type)">
          <div id="address-lines">
            {{ addr.address_line_one }}
            {{ addr.address_line_two }}
          </div>
          <div id="address-details">
            {{ addr.city }}, {{ addr.state }}
            {{ addr.zip_code }}
          </div>
        </div>
      </div>
    </div>
    <div id="no-addresses" v-else>No public addresses listed</div>
  </div>
</template>

<script>
const validEmailTypes = ["OTHER", "STUDENT_LOCAL", "WORK", "HOME"];
export default {
  name: "MentorCardAddresses",
  props: {
    addresses: {
      type: Array,
      required: true,
    },
  },
  methods: {
    showAddress: function (type) {
      return validEmailTypes.includes(type); // OTHER due to legacy data
    },
  },
};
</script>

<style scoped></style>
