<template>
  <div>
    <modal
      v-if="show_detail_modal"
      :label="'Detail'"
      :submit_btn_text="'Close'"
      :no-submit="true"
      @close="closeModal"
      @submit="closeModal"
      large
      blocking
      locked
    >
      <template v-slot:content>
        <admin-students-detail :_id="person_id" v-if="showing_student" />
        <admin-mentor-detail :_id="person_id" v-else />
      </template>
    </modal>
    <div class="header-text pb-3" data-cy="header-admin-pairing">
      Pairing Management
    </div>
    <div class="subhead-text pb-3">
      Manage pairings for the {{ currentTermName }} Mentor Externship Program.
    </div>
    <div class="grid grid-cols-4 gap-3">
      <div class="col-span-3">
        <div class="box-style">
          <div class="grid grid-cols-3 gap-3">
            <div class="row-span-1 col-span-3">
              <pairing-management-buttons
                @collapse-all="collapseAll"
                @expand-all="expandAll"
                @auto-pair="autoPair"
                @suggest-pair="suggestPair"
                @unlink-pair="unlinkPair"
                @link-pair="linkPair"
                @set-pairing-flag="setFlagAndLink($event)"
                @set-modal-content="this.modal_content = $event"
                :modal-content="modal_content"
              />
            </div>
            <div class="row-span-3 col-span-3">
              <div>
                <div class="grid grid-cols-2 gap-5">
                  <div
                    v-for="(list, idx) in student_mentor_list_config"
                    :key="idx"
                    class="box-style"
                  >
                    <div class="" :id="`pairing-list-${idx}`">
                      <mentor-student-list-header
                        :title="list.title"
                        :pagination-page="
                          list.title === 'Students'
                            ? studentPaginationPage
                            : mentorPaginationPage
                        "
                        :pagination-total-pages="
                          list.title === 'Students'
                            ? studentPaginationTotalPages
                            : mentorPaginationTotalPages
                        "
                        :pagination-total-items="
                          list.title === 'Students'
                            ? studentPaginationTotalItems
                            : mentorPaginationTotalItems
                        "
                        :pagination-page-size="itemsPerPage"
                      />
                      <div class="pb-3 overflow-y-scroll max-h-128">
                        <div
                          v-if="checkLoading(list.title.toLowerCase())"
                          class="p-3"
                        >
                          <LoadingSpinner />
                        </div>
                        <mentor-student-list
                          v-else
                          :list="list"
                          :expand-all="expand_all"
                          @selected="updateSelection"
                          @showStudentModal="showStudent"
                          @showMentorModal="showMentor"
                          :selected-id="selected"
                        />
                      </div>
                      <div id="button-space" class="flex justify-between pt-4">
                        <button
                          class="w-1/3 inline-block py-1 px-3 bg-purple hover:bg-dark-purple rounded-full text-white"
                          :aria-label="`prev-${list.title}-page`"
                          @click="prev(list.title.toLowerCase())"
                        >
                          Previous
                        </button>
                        <button
                          class="w-1/3 inline-block py-1 px-3 bg-purple hover:bg-dark-purple rounded-full text-white"
                          :aria-label="`next-${list.title}-page`"
                          @click="next(list.title.toLowerCase())"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-1">
        <div class="box-style mb-4">
          <h3 class="thirdhead-text pb-3">Selected Pair</h3>
          <div class="mb-1">
            <strong>Student:</strong> <span> {{ to_pair_student }} </span>
          </div>
          <div>
            <strong>Mentor: </strong> <span> {{ to_pair_mentor }}</span>
          </div>
        </div>
        <filters
          @ApplyFilters="updateFilter($event)"
          @SearchEmployer="searchEmployers"
          :mentor_employers_options="mentor_employers"
          :mentor_job_titles_options="mentor_job_titles"
          :mentor_cities_options="mentor_cities"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import Filters from "./subcomponents/PairingManagementFilters";
import { del, get, post } from "@/composables/httpUtil";
import MentorStudentListHeader from "@/views/admin/pairing/subcomponents/mentor-student-lists/subcomponents/MentorStudentListHeader";
import MentorStudentList from "@/views/admin/pairing/subcomponents/mentor-student-lists/MentorStudentList";
import PairingManagementButtons from "@/views/admin/pairing/subcomponents/PairingManagementButtons";
import Modal from "@/components/modal/Modal";
import AdminMentorDetail from "@/views/admin/mentors/MentorDetail";
import AdminStudentsDetail from "@/views/admin/students/StudentDetail";
import { mapGetters } from "vuex";

export default {
  name: "PairingManagement",
  components: {
    AdminStudentsDetail,
    AdminMentorDetail,
    PairingManagementButtons,
    MentorStudentList,
    MentorStudentListHeader,
    LoadingSpinner,
    Filters,
    Modal,
  },
  data() {
    return {
      mentor_employers: [],
      mentor_job_titles: [],
      mentor_cities: [],

      base_pairing_url: "admin/pairings",

      selected: {
        student: null,
        mentor: null,
      },
      to_pair_student: null,
      to_pair_mentor: null,

      expand_all: false,

      show_modal: false,
      show_detail_modal: false,
      showing_student: false,
      person_id: null,

      pairing_flag: false,

      student_mentor_list_config: [
        {
          title: "Students",
          header: "Student Pagination Data",
          filter_placeholder: "Filter by student name",
          data: [],
        },
        {
          title: "Mentors",
          header: "Mentor Pagination Data",
          filter_placeholder: "Filter by mentor name",
          data: [],
        },
      ],

      filters: {
        mentor_name: "",
        mentor_practice_area: null,
        mentor_employer_name: null,
        mentor_job_titles: null,
        mentor_status: ["Active"],
        mentor_city: null,
        mentor_paired: false,
        mentor_unpaired: false,

        student_name: "",
        student_practice_area: null,
        student_transportation: null,
        student_acad_level: null,
        student_paired: false,
        student_unpaired: false,
        student_status: ["Active"],
      },

      modal_content: "",

      employer_query: "",

      students_loading: false,
      mentors_loading: false,

      studentPaginationPage: 1,
      mentorPaginationPage: 1,
      studentPaginationTotalPages: 0,
      studentPaginationTotalItems: 0,
      mentorPaginationTotalPages: 0,
      mentorPaginationTotalItems: 0,
      itemsPerPage: 25,
    };
  },
  watch: {
    employer_query(new_value) {
      // typeahead function for the employers filter input
      this.searchEmployers(new_value);
    },
  },
  async created() {
    await Promise.allSettled([
      this.fetchListOfMentors(this.filters),
      this.fetchListOfStudents(this.filters),
    ]);
  },
  methods: {
    checkSelection: function () {
      if (!this.selected.student && !this.selected.mentor) {
        this.modal_content = "pairing_error";
        this.show_modal = true;
        return false;
      } else return true;
    },
    checkLoading: function (title) {
      switch (title) {
        case "students":
          return this.students_loading;
        case "mentors":
          return this.mentors_loading;
        default:
          return false;
      }
    },
    showMentor: function (id) {
      console.log(id)
      this.showing_student = false;
      this.show_detail_modal = true;
      this.person_id = id;
    },
    showStudent: function (id) {
      this.showing_student = true;
      this.show_detail_modal = true;
      this.person_id = id;
    },
    closeModal: function () {
      this.show_modal = false;
      this.show_detail_modal = false;
    },
    autoPair: function () {
      // console.log("autoPair");
    },
    suggestPair: function () {
      if (!this.selected.student) {
        this.modal_content = "suggest";
        this.show_modal = true;
        return;
      }
      let student_profile;
      this.student_mentor_list_config[0].data.find((student) => {
        let profile = student.profiles.find((profile) => {
          return profile.student_at_level_id === this.selected.student;
        });
        if (profile) {
          student_profile = profile;
          return true;
        }
      });
      this.filters.mentor_practice_area = student_profile.interest_areas.map(
        (interest_area) => {
          {
            return interest_area.practice_area_id;
          }
        }
      );
      this.fetchListOfMentors(this.filters);
      return false;
    },
    unlinkPair: function () {
      // console.log("unlinkPair");
      if (!this.checkSelection()) {
        return;
      }
      Object.entries(this.student_mentor_list_config[1].data).forEach(
        (mentor) => {
          if (mentor[1].mentor_id === this.selected.mentor) {
            // console.log("mentor", mentor[1]);
            Object.entries(mentor[1].student_pairings).forEach((pairing) => {
              // need to check for an end date on pairing as well
              if (pairing[1].student_at_level_id === this.selected.student) {
                // && !pairing[1].is_closed && !this.pairing_flag
                // console.log(pairing);
                if (
                  pairing[1].is_pairing_closed === true &&
                  pairing[1].is_pairing_invalid === true
                ) {
                  // console.log("Was, and now is no more");
                  this.modal_content = "pairing_invalid";
                } else if (pairing[1].is_pairing_closed === true) {
                  // console.log("Already closed");
                  this.modal_content = "pairing_closed";
                } else if (!this.pairing_flag) {
                  // console.log("Confirming");
                  this.modal_content = "delete_warn";
                } else {
                  // console.log("Deleting");
                  this.deletePairing(pairing[1].pairing_id);
                }
              }
            });
          }
        }
      );
    },
    deletePairing: function (id) {
      // console.log("deletePairing", id);
      // backend will check for student fieldworks, if no fieldworks the pairing is invalid and closed
      // if fieldworks, the pairing is only closed
      del(this.base_pairing_url + "/" + id)
        .then(() => {
          Promise.allSettled([
            this.fetchListOfMentors(this.filters),
            this.fetchListOfStudents(this.filters),
          ]);
        })
        .then(() => {
          this.modal_content = "delete_success";
        })
        .finally(() => {
          this.pairing_flag = false;
        })
        .catch((error) => {
          console.log("error (deletePairing):", error);
        });
    },
    linkPair: async function () {
      if (!this.checkSelection()) {
        return;
      }
      if (
        (await this.findPairing(this.selected.student, this.selected.mentor)) &&
        !this.pairing_flag
      ) {
        this.modal_content = "existing";
      } else if (!this.pairing_flag) {
        this.modal_content = "pairing_warn";
      } else {
        this.students_loading = true;
        this.mentors_loading = true;
        post(this.base_pairing_url, {
          mentor_id: this.selected.mentor,
          student_at_level_id: this.selected.student,
        })
          .then(() => {
            Promise.allSettled([
              this.fetchListOfMentors(this.filters),
              this.fetchListOfStudents(this.filters),
            ]);
          })
          .then(() => {
            this.modal_content = "success";
          })
          .finally(() => {
            this.selected.mentor = null;
            this.selected.student = null;
            this.pairing_flag = false;
          })
          .catch((err) => {
            this.students_loading = false;
            this.mentors_loading = false;
            console.log("error (linkPair):", err);
          });
      }
    },
    findPairing: async function (student, ment) {
      let paired = false;
      let stuSalPairings = [];
      stuSalPairings.push(...(await this.fetchSalPairings(student)));
      if (stuSalPairings.length > 0) {
        paired = true;
      } else {
        Object.entries(this.student_mentor_list_config[1].data).forEach(
          (mentor) => {
            if (mentor[1].mentor_id === ment) {
              if (mentor[1].student_pairings.length > 0) {
                mentor[1].student_pairings.forEach((pairing) => {
                  !pairing.is_pairing_closed ? (paired = true) : null;
                });
              }
            }
          }
        );
      }
      return paired;
    },
    setFlagAndLink: function (flag) {
      this.pairing_flag = flag.value;
      switch (flag.exec) {
        case "Delete":
          this.unlinkPair();
          break;
        case "Pair":
          this.linkPair();
          break;
        default:
          console.log("error (setFlagAndLink), invalid exec value");
      }
    },
    collapseAll: function () {
      this.expand_all = false;
    },
    expandAll: function () {
      this.expand_all = true;
    },
    updateFilter: async function (filters) {
      console.log(filters);
      this.filters = { ...filters.filters };
      this.filters.reset_pages = filters.reset_pages;
      if ("reset_pages" in this.filters && this.filters.reset_pages) {
        this.filters.reset_pages = false;
        this.studentPaginationPage = 1;
        this.mentorPaginationPage = 1;
      }
      await Promise.allSettled([
        this.fetchListOfMentors({ ...this.filters }),
        this.fetchListOfStudents({ ...this.filters }),
      ]);
    },
    fetchListOfStudents: function (filters) {
      filters["filters"] ? (filters = filters["filters"]) : "";
      let url = `admin/students?page=${this.studentPaginationPage}&limit=${this.itemsPerPage}&term_id=${this.currentTermId}`;
      url += filters.student_acad_level
        ? `&academic_level=${filters.student_acad_level.join(
            "&academic_level="
          )}`
        : "";
      url += filters.student_name ? `&name=${filters.student_name}` : "";
      url += filters.student_paired ? "&paired=true" : "";
      url += filters.student_unpaired ? "&unpaired=true" : "";
      url += filters.student_practice_area
        ? `&interest_area=${filters.student_practice_area.join(
            "&interest_area="
          )}`
        : "";
      url += filters.student_transportation
        ? `&transport=${filters.student_transportation
            .join("&transport=")
            .trim()
            .toUpperCase()
            .replace("TRANSPORT", "transport")}`
        : "";
      url += filters.student_status
        ? `&status=${filters.student_status
            .join("&status=")
            .trim()
            .replace(" ", "_")
            .toUpperCase()
            .replace("STATUS", "status")}`
        : "";
      this.students_loading = true;
      get(url)
        .then((results) => {
          this.student_mentor_list_config[0].data = results.data;

          this.studentPaginationTotalItems = results.count;
          this.studentPaginationTotalPages = results.total_pages;
        })
        .catch((error) => {
          //TODO: do something with this error and display it to the user
          console.log("error (fetchListOfStudents):", error);
        })
        .finally(() => (this.students_loading = false));
    },
    fetchListOfMentors: function (filters) {
      let url = `admin/mentors?page=${this.mentorPaginationPage}&limit=${this.itemsPerPage}`;
      url += filters.mentor_name ? `&name=${filters.mentor_name}` : "";
      url += filters.mentor_paired ? "&paired=true" : "";
      url += filters.mentor_unpaired ? "&unpaired=true" : "";
      url += filters.mentor_practice_area
        ? `&practice_area=${filters.mentor_practice_area.join(
            "&practice_area="
          )}`
        : "";
      url += filters.mentor_employer_name
        ? `&employer=${filters.mentor_employer_name.join("&employer=")}`
        : "";
      url += filters.mentor_job_titles
        ? `&job_titles=${filters.mentor_job_titles.join("&job_titles=")}`
        : "";
      url += filters.mentor_status
        ? `&status=${filters.mentor_status
            .join("&status=")
            .trim()
            .replaceAll(" ", "_")
            .toUpperCase()
            .replace("STATUS", "status")}`
        : "";
      url += filters.mentor_city
        ? `&city=${filters.mentor_city.join("&city=")}`
        : "";
      this.mentors_loading = true;
      get(url)
        .then((results) => {
          this.student_mentor_list_config[1].data = results.data;

          this.mentorPaginationTotalItems = results.count;
          this.mentorPaginationTotalPages = results.total_pages;
          this.mapMentorOptions(results.data);
        })
        .catch((error) => {
          console.log("error (fetchListOfMentors):", error);
        })
        .finally(() => (this.mentors_loading = false));
    },
    mapMentorOptions: function (mentors) {
      let job_titles = [];
      let cities = [];
      mentors.forEach((mentor) => {
        if (mentor.employer.length > 0) {
          mentor.employer.forEach((employer) => {
            !job_titles.includes(employer.job_title)
              ? job_titles.push(employer.job_title)
              : "";
            employer.employer.address &&
            !cities.includes(employer.employer.address.city)
              ? cities.push(employer.employer.address.city)
              : "";
          });
        }
      });
      this.mentor_job_titles = job_titles;
      this.mentor_cities = cities;
    },
    searchEmployers: async function (query) {
      let url = `admin/employers?name=${query}`;
      if (query) {
        return await get(url)
          .then((results) => {
            this.mentor_employers = [];
            return results.map((item) => {
              this.mentor_employers.push({
                value: item.employer_id,
                label: item.employer_name,
              });
            });
          })
          .catch((error) => {
            console.log("error (searchEmployers):", error);
          });
      } else {
        return [];
      }
    },
    updateSelection: function (selection) {
      Object.keys(selection).forEach((key) => {
        selection[key] ? (this.selected[key] = selection[key]) : null;
        if (selection[key]) {
          this.getName(key, selection[key]);
        }
      });
    },
    getName: function (key, id) {
      if (key === "student") {
        this.student_mentor_list_config[0].data.forEach((student) => {
          student.profiles.forEach((profile) => {
            if (profile.student_at_level_id === id) {
              this.to_pair_student = student.firstname + " " + student.lastname;
            }
          });
        });
      }
      if (key === "mentor") {
        this.student_mentor_list_config[1].data.forEach((mentor) => {
          if (mentor.mentor_id === id) {
            this.to_pair_mentor = mentor.firstname + " " + mentor.lastname;
          }
        });
      }
    },
    fetchSalPairings: function (sal) {
      return get(`admin/pairings?sal_id=${sal}`)
        .then((results) => {
          return results.data;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    },
    next: function (list_name) {
      if (list_name === "students") {
        if (this.studentPaginationPage === this.studentPaginationTotalPages) {
          return;
        }
        this.studentPaginationPage++;
        this.fetchListOfStudents({ ...this.filters });
      } else if (list_name === "mentors") {
        if (this.mentorPaginationPage === this.mentorPaginationTotalPages) {
          return;
        }
        this.mentorPaginationPage++;
        this.fetchListOfMentors({ ...this.filters });
      }
    },
    prev: function (list_name) {
      if (list_name === "students") {
        if (this.studentPaginationPage === 1) {
          return;
        }
        this.studentPaginationPage--;
        this.fetchListOfStudents({ ...this.filters });
      } else if (list_name === "mentors") {
        if (this.mentorPaginationPage === 1) {
          return;
        }
        this.mentorPaginationPage--;
        this.fetchListOfMentors({ ...this.filters });
      }
    },
  },
  computed: {
    ...mapGetters(["lookup_current_term"]),
    currentTermId: function () {
      return this.lookup_current_term.term_id;
    },
    currentTermName: function () {
      return this.lookup_current_term.name;
    },
    currentTermStartDate: function () {
      return this.lookup_current_term.start_date;
    },
    currentTermEndDate: function () {
      return this.lookup_current_term.end_date;
    },
  },
};
</script>

<style scoped></style>
