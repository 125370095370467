<template>
  <div class="grid">
    <div class="col-start-1 w-56">
      <div>
        <a
          class="text-purple hover:cursor-pointer"
          aria-label="see mentor detail page for this mentor"
          @click="showMentorModal(item.mentor_id)"
        >
          {{ item.prefix }}
          {{ item.firstname }}
          {{ item.lastname }}
        </a>
      </div>
      <div class="text-steel-gray border-b border-slate-gray">
        <mentor-card-practice-areas
          :practice_areas="item.profile.practice_areas"
          :show-all="expanded"
        />
      </div>
      <div v-if="expanded">
        <div class="text-steel-gray border-b border-slate-gray">
          <mentor-card-addresses :addresses="item.address" />
        </div>
        <div class="text-steel-gray border-b border-slate-gray">
          <mentor-card-pairings :pairings="item.student_pairings" @show-student-modal="showStudentModal" />
        </div>
        <div class="text-steel-gray border-b border-slate-gray">
          <mentor-card-employers :employers="item.employer" />
        </div>
      </div>
    </div>
    <div class="col-start-2">
      <div class="px-5 -mt-1">
        <button class="text-3xl text-purple" @click="expanded = !expanded">
          <font-awesome-icon :icon="expanded ? 'caret-up' : 'caret-down'" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import MentorCardPracticeAreas from "@/views/admin/pairing/subcomponents/mentor-card/subcomponents/MentorCardPracticeAreas";
import MentorCardAddresses from "@/views/admin/pairing/subcomponents/mentor-card/subcomponents/MentorCardAddresses";
import MentorCardEmployers from "@/views/admin/pairing/subcomponents/mentor-card/subcomponents/MentorCardEmployers";
import MentorCardPairings from "@/views/admin/pairing/subcomponents/mentor-card/subcomponents/MentorCardPairings";
export default {
  name: "MentorCard",
  emits: ["showMentorModal", "showStudentModal"],
  components: {
    MentorCardAddresses,
    MentorCardPracticeAreas,
    MentorCardEmployers,
    MentorCardPairings,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    setExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: Boolean(this.setExpanded),
    };
  },
  methods: {
    showMentorModal(mentorId) {
      this.$emit("showMentorModal", mentorId);
    },
    showStudentModal(studentId) {
      this.$emit("showStudentModal", studentId);
    },
  },
  watch: {
    setExpanded(newVal) {
      this.expanded = newVal;
    },
  },
};
</script>

<style scoped></style>
