<template>
  <div>
    <div v-if="practice_areas.length">
      <div v-for="(factor, idx) in sorted_practice_areas" :key="idx">
        &bull; {{ factor.description }}&nbsp;
      </div>
    </div>
    <div v-else>No practice areas listed</div>
  </div>
</template>

<script>
export default {
  name: "MentorCardPracticeAreas",
  props: {
    practice_areas: {
      type: Array,
      required: true,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sorted_practice_areas: function () {
      let output = [...this.practice_areas];
      return output.sort((a, b) => a.priority - b.priority);
    },
  },
};
</script>

<style scoped></style>
