<template>
  <div class="grid">
    <div class="col-start-1 w-56">
      <div>
        <a
          class="text-purple hover:cursor-pointer"
          aria-label="see student detail page for this student"
          @click="showStudentModal(currentProfile.student.person_id)"
        >
          {{ currentProfile.student.firstname }}
          {{ currentProfile.student.lastname }}
          ({{ currentProfile.acad_level.level }})
        </a>
      </div>
      <div class="text-steel-gray border-b border-slate-gray">
        <student-card-interest-areas
          :interest_areas="currentProfile.interest_areas"
          :show-all="expanded"
        />
      </div>
      <div v-if="expanded">
        <div class="text-steel-gray border-b border-slate-gray">
          <mentor-card-addresses :addresses="item.address" />
        </div>
        <div class="text-steel-gray border-b border-slate-gray">
          <div>
            <loading-spinner v-if="loading" />
            <student-card-pairings
              v-else
              :pairings="pairings"
              :mentor-name="{
                firstname: mentor_first_name,
                lastname: mentor_last_name,
                honorific: mentor_honorific,
              }"
              :mentor-id="mentor_id"
              @showMentorModal="showMentorModal"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-start-2">
      <div class="px-5 -mt-1">
        <button
          :aria-label="
            expanded ? 'Collapse person details' : 'Expand person details'
          "
          class="text-3xl text-purple"
          @click="expanded = !expanded"
        >
          <font-awesome-icon :icon="expanded ? 'caret-up' : 'caret-down'" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import StudentCardInterestAreas from "@/views/admin/pairing/subcomponents/student-card/subcomponents/StudentCardInterestAreas";
import MentorCardAddresses from "@/views/admin/pairing/subcomponents/mentor-card/subcomponents/MentorCardAddresses";
import { get } from "@/composables/httpUtil";
import StudentCardPairings from "@/views/admin/pairing/subcomponents/student-card/subcomponents/StudentCardPairings";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
export default {
  name: "StudentCard",
  emits: ["showMentorModal", "showStudentModal"],
  components: {
    LoadingSpinner,
    StudentCardPairings,
    MentorCardAddresses,
    StudentCardInterestAreas,
  },
  props: {
    item: {
      // needed for historical pairings
      type: Object,
      required: true,
    },
    currentProfile: {
      // needed for app functionalities
      type: Object,
      required: true,
    },
    setExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: Boolean(this.setExpanded),
      pairings: [],
      loading: false,
      mentor_first_name: null,
      mentor_last_name: null,
      mentor_honorific: null,
      mentor_id: null,
    };
  },
  watch: {
    setExpanded(newVal) {
      this.expanded = newVal;
    },
    expanded(newVal) {
      if (newVal === true) {
        this.fetchStudentPairings();
      }
    },
  },
  methods: {
    // As soon as I finished writing this, I realized it could be done more cleanly using async/await...
    fetchStudentPairings: function () {
      this.item.profiles.forEach((profile) => {
        this.loading = true;
        this.pairings = [];
        let url = `admin/pairings?sal_id=${profile.student_at_level_id}`;
        get(url)
          .then((response) => {
            this.pairings.push(...response.data);
          })
          .finally(() => {
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    showMentorModal: function (mentor_id) {
      this.$emit("showMentorModal", mentor_id);
    },
    showStudentModal: function (student_id) {
      this.$emit("showStudentModal", student_id);
    },
  },
  computed: {
    // current_student_profile: function () {
    //   let curr_profile;
    //   this.item.profiles.forEach((profile) => {
    //     if (!curr_profile) {
    //       curr_profile = profile;
    //     } else if (profile.current) {
    //       curr_profile = profile;
    //     }
    //   });
    //   return curr_profile;
    // },
  },
};
</script>

<style scoped></style>
