<template>
  <div>
    <div v-if="clean_pairings.length > 0">
      Pairings:
      <div v-for="(pairing, idx) in clean_pairings" :key="idx" class="p-1">
        <div v-if="!pairing.is_pairing_invalid" class="italic">
          <a
            class="text-purple hover:cursor-pointer"
            aria-label="see student detail page for this student"
            @click="
              showStudentModal(pairing.student_at_level.student.person_id)
            "
          >
            {{ pairing.student_at_level.student.firstname }}
            {{ pairing.student_at_level.student.lastname }}
          </a>
          -
          {{
            pairing.end_date
              ? convertToIOS(pairing.end_date).split(" ")[0]
              : "Current"
          }}
        </div>
      </div>
    </div>
    <div v-else>No pairings listed</div>
  </div>
</template>

<script>
import { convertToIOS } from "@/composables/date_utils";
export default {
  name: "MentorCardPairings",
  emits: ["showStudentModal"],
  props: {
    pairings: {
      type: Array,
      required: true,
    },
  },
  methods: {
    convertToIOS,
    showStudentModal(studentId) {
      this.$emit("showStudentModal", studentId);
    },
  },
  computed: {
    clean_pairings: function () {
      // remove all invalid pairings from the list
      let clean = [];
      for (let pairing of this.pairings) {
        if (!pairing.is_pairing_invalid) {
          clean.push(pairing);
        }
      }
      return clean;
    },
  },
};
</script>

<style scoped></style>
