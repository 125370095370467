<template>
  <ul v-if="list.data.length > 0">
    <li
      v-for="(item, idx) in list.data"
      :key="idx"
      class="border-b border-steel-gray py-3 flex gap-3 w-full"
    >
      <div class="py-1.5">
        <input
          :id="item.student_id ? item.student_id : item.mentor_id"
          type="radio"
          :name="item.student_id ? 'student' : 'mentor'"
          v-model="selected[item.student_id ? 'student' : 'mentor']"
          :value="
            item.student_id
              ? currentStudentProfile(item).student_at_level_id
              : item.mentor_id
          "
        />
      </div>
      <label
        class="w-full"
        :for="item.student_id ? item.student_id : item.mentor_id"
      >
        <div v-if="list.title === 'Students'">
          <student-card
            :item="item"
            :current-profile="currentStudentProfile(item)"
            :set-expanded="expandAll"
            @showMentorModal="showMentor"
            @showStudentModal="showStudent"
          />
        </div>
        <div v-else>
          <mentor-card
            :item="item"
            :set-expanded="expandAll"
            @showMentorModal="showMentor"
            @showStudentModal="showStudent"
          />
        </div>
      </label>
    </li>
  </ul>
  <div v-else>No {{ list.title.toLowerCase() }} data found.</div>
</template>

<script>
import MentorCard from "@/views/admin/pairing/subcomponents/mentor-card/MentorCard";
import StudentCard from "@/views/admin/pairing/subcomponents/student-card/StudentCard";
export default {
  name: "MentorStudentList",
  emits: ["selected", "showMentorModal", "showStudentModal"],
  components: {
    MentorCard,
    StudentCard,
  },
  props: {
    list: {
      type: Object,
      required: true,
    },
    expandAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedId: {
      type: Object,
      default: null,
    },
  },
  created() {
    this.selected.student = this.selectedId.student;
    this.selected.mentor = this.selectedId.mentor;
  },
  data() {
    return {
      selected: {
        student: null,
        mentor: null,
      },
    };
  },
  watch: {
    selected: {
      handler(newVal) {
        this.$emit("selected", {
          student: newVal.student,
          mentor: newVal.mentor,
        });
      },
      deep: true,
    },
  },
  methods: {
    showMentor(id) {
      this.$emit("showMentorModal", id);
    },
    showStudent(id) {
      this.$emit("showStudentModal", id);
    },
    currentStudentProfile(student) {
      let curr_profile;
      student.profiles.forEach((profile) => {
        if (!curr_profile) {
          curr_profile = profile;
        } else if (profile.current) {
          curr_profile = profile;
        }
      });
      return curr_profile;
    },
  },
};
</script>

<style scoped></style>
