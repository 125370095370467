<template>
  <div>
    Interest Areas:
    <div v-if="interest_areas.length" class="p-1">
      <div
        v-for="(factor, idx) in sortedInterestAreas"
        :key="idx"
        class="inline"
      >
        <div v-if="factor.display">&bull; {{ factor.description }}&nbsp;</div>
      </div>
    </div>
    <div v-else>No interest areas listed</div>
  </div>
</template>

<script>
// unicode bullet point is U+2022
export default {
  name: "StudentCardInterestAreas",
  props: {
    interest_areas: {
      type: Array,
      required: true
    },
    showAll: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    sortedInterestAreas: function () {
      let output = [...this.interest_areas];
      return output.sort((a, b) => a.priority - b.priority);
    },
  },
};
</script>

<style scoped>

</style>
