<template>
  <div>
    <div v-if="employers.length">
      Employers:
      <div v-for="(employer, idx) in employers" :key="idx" class="p-1">
        <div v-if="employer.current">
          {{ employer.employer.employer_name ? employer.employer.employer_name : "Employer name not properly set" }}
          <div v-if="employer.employer.address !== null">
            <div>
              {{ employer.employer.address.address_line_one }}
              {{ employer.employer.address.address_line_two }}
            </div>
            <div>
              {{ employer.employer.address.city }},
              {{ employer.employer.address.state }}
              {{ employer.employer.address.zip_code }}
            </div>
          </div>
          <div v-else>Employer address information not properly set</div>
        </div>
      </div>
    </div>
    <div v-else>No employers listed</div>
  </div>
</template>

<script>
export default {
  name: "MentorCardEmployers",
  props: {
    employers: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
