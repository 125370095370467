<template>
  <div class="flex justify-between pb-4">
    <h2 class="secondhead-text">{{ title }}</h2>
    <div class="grid grid-rows-2">
      <label class="text-steel-gray row-start-1"
        >{{ paginationPage }}
        of
        {{ paginationTotalPages }}</label
      >
      <label class="text-steel-gray row-start-2">
        {{ paginationTotalItems }} results
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "MentorStudentListHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
    paginationPage: {
      type: Number,
      required: true,
    },
    paginationTotalPages: {
      type: Number,
      required: true,
    },
    paginationTotalItems: {
      type: Number,
      required: true,
    },
    paginationPageSize: {
      type: Number,
      default: 25,
    },
  },
};
</script>

<style scoped></style>
