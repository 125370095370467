<template>
  <div class="flex gap-3 text-purple text-sm">
    <div
      v-for="(button, idx) in action_buttons_config"
      :key="idx"
      class="w-full"
    >
      <button
        :aria-label="`action-${button.text}`"
        class="w-full border rounded hover:bg-purple hover:text-white"
        @click="button.action"
      >
        <font-awesome-icon :icon="button.icon" />
        <span class="px-1">{{ button.text }}</span>
      </button>
    </div>
  </div>
  <modal
    class="w-full"
    v-if="show_modal"
    :label="current_content.label"
    :submit_btn_text="current_content.submit_btn_text"
    :show_cancel="current_content.show_cancel"
    :cancel_btn_text="current_content.cancel_btn_text"
    @submit="
      closeModalWithAction({
        value: current_content.show_cancel,
        exec: current_content.submit_btn_text,
      })
    "
    @close="closeModalNoAction"
    medium
    blocking
  >
    <template v-slot:content>
      {{ current_content.content }}
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/modal/Modal";

export default {
  name: "PairingManagementButtons",
  components: {
    Modal,
  },
  props: {
    modalContent: {
      type: String,
      default: () => "",
    },
  },
  emits: [
    "collapseAll",
    "expandAll",
    "autoPair",
    "suggestPair",
    "unlinkPair",
    "linkPair",
    "setPairingFlag",
    "setModalContent",
  ],
  data() {
    return {
      action_buttons_config: [
        {
          icon: "minus",
          text: "Collapse All",
          action: this.collapseAll,
        },
        {
          icon: "plus",
          text: "Expand All",
          action: this.expandAll,
        },
        {
          icon: "lightbulb",
          text: "Suggest Pairing",
          action: this.suggestPair,
        },
        {
          icon: "unlink",
          text: "Remove Pairing",
          action: this.unlinkPair,
        },
        {
          icon: "link",
          text: "Create Pairing",
          action: this.linkPair,
        },
      ],
      modal_content_config: {
        success: {
          label: "Success",
          submit_btn_text: "Close",
          show_cancel: false,
          cancel_btn_text: "",
          content: "Pairing success!",
        },
        existing: {
          label: "Pairing Exists",
          submit_btn_text: "Pair",
          show_cancel: true,
          cancel_btn_text: "Cancel",
          content:
            "This student has an existing pairing. Would you like to proceed with this pairing?",
        },
        pairing_error: {
          label: "Oops!",
          submit_btn_text: "Close",
          show_cancel: false,
          cancel_btn_text: "",
          content:
            "Action could not execute, please make sure a student and a mentor are selected before trying again.",
        },
        pairing_closed: {
          label: "Oops!",
          submit_btn_text: "OK",
          show_cancel: false,
          cancel_btn_text: "",
          content: "This pairing is already closed.",
        },
        pairing_invalid: {
          label: "Oops!",
          submit_btn_text: "OK",
          show_cancel: false,
          cancel_btn_text: "",
          content: "This pairing was invalidated in the past.",
        },
        pairing_warn: {
          label: "Head's up!",
          submit_btn_text: "Pair",
          show_cancel: true,
          cancel_btn_text: "Cancel",
          content: "Are you sure you want to pair this mentor and student?",
        },
        pairing_exists: {
          // Maybe the backend should be doing this check?
          label: "Oops!",
          submit_btn_text: "Close",
          show_cancel: false,
          cancel_btn_text: "",
          content: "This pairing already exists.",
        },
        delete_warn: {
          label: "Delete Pairing",
          submit_btn_text: "Delete",
          show_cancel: true,
          cancel_btn_text: "Cancel",
          content: "Are you sure you want to delete this pairing?",
        },
        delete_success: {
          label: "Delete Pairing",
          submit_btn_text: "Close",
          show_cancel: false,
          cancel_btn_text: "",
          content: "Pairing deleted!",
        },
        suggest: {
          label: "Oops!",
          submit_btn_text: "Close",
          show_cancel: false,
          cancel_btn_text: "",
          content:
            "Please select a student from the student list before trying the Suggest Pairing action again.",
        },
      },
      current_content: null,
      show_modal: false,
    };
  },
  watch: {
    modalContent: {
      handler(new_content) {
        this.setCurrentContent(new_content);
        if (new_content !== null) this.show_modal = true;
      },
      deep: true,
    },
  },
  methods: {
    collapseAll() {
      this.$emit("collapseAll");
    },
    expandAll() {
      this.$emit("expandAll");
    },
    autoPair() {
      this.$emit("autoPair");
    },
    suggestPair() {
      this.$emit("suggestPair");
    },
    unlinkPair() {
      this.$emit("unlinkPair");
    },
    linkPair() {
      this.$emit("linkPair");
    },
    closeModalWithAction(action) {
      this.show_modal = false;
      this.setCurrentContent(null);
      action.value
        ? this.$emit("setPairingFlag", {
            exec: action.exec,
            value: action.value,
          })
        : null;
      this.$emit("setModalContent", null);
    },
    closeModalNoAction() {
      this.show_modal = false;
      this.setCurrentContent(null);
      this.$emit("setModalContent", null);
    },
    setCurrentContent(content) {
      content === null
        ? (this.current_content = null)
        : (this.current_content = this.modal_content_config[content]);
    },
  },
};
</script>

<style scoped></style>
